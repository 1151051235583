import config from '../../package.json';

export default {
  companyNameShort: '网新新思',
  companyNameLong: '深圳市网新新思软件有限公司',
  version: `v${config.version}`,
  copyright: '2019-2024',
  // 入职须知视频合法url校验
  CKEditorRegExp: /^https:\/\/ims.szis.com.cn\/files\//,
  // webSocket连接地址
  webSocketUrl: 'wss://ims.szis.com.cn',
  subVersion: '0fe0115c',
  backendSvnVersion: '28de7e41'
};
